import { UnsafeAction as Action } from '../../interfaces';
import { Update } from '@ngrx/entity';
import { TimeOffBalanceModel } from './time-off-balance.model';

const ACTION_PREFIX = '[TimeOff]';

export const timeOffActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  DEACTIVATE: `${ACTION_PREFIX} Deactivate`,
  DEACTIVATE_SUCCESS: `${ACTION_PREFIX} Deactivate Success`,
  DEACTIVATE_FAILED: `${ACTION_PREFIX} Deactivate Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,
};

export class TimeOffBalanceAction {
  static load(): Action {
    return {
      type: timeOffActionType.LOAD,
    };
  }

  static loadSuccess(timeOffBalances): Action {
    return {
      type: timeOffActionType.LOAD_SUCCESS,
      payload: timeOffBalances,
    };
  }

  static loadFailed(err): Action {
    return {
      type: timeOffActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(): Action {
    return {
      type: timeOffActionType.ADD,
    };
  }

  static addSuccess(data): Action {
    return {
      type: timeOffActionType.ADD_SUCCESS,
      payload: data,
    };
  }

  static addFailed(err): Action {
    return {
      type: timeOffActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(): Action {
    return {
      type: timeOffActionType.UPDATE,
    };
  }

  static updateSuccess(data: Update<TimeOffBalanceModel>): Action {
    return {
      type: timeOffActionType.UPDATE_SUCCESS,
      payload: data,
    };
  }

  static updateFailed(err): Action {
    return {
      type: timeOffActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static deactivate(data): Action {
    return {
      type: timeOffActionType.DEACTIVATE,
      payload: data,
    };
  }

  static deactivateSuccess(data): Action {
    return {
      type: timeOffActionType.DEACTIVATE_SUCCESS,
      payload: data,
    };
  }

  static deactivateFailed(data): Action {
    return {
      type: timeOffActionType.DEACTIVATE_FAILED,
      payload: data,
    };
  }

  static activate(data): Action {
    return {
      type: timeOffActionType.ACTIVATE,
      payload: data,
    };
  }

  static activateSuccess(data): Action {
    return {
      type: timeOffActionType.ACTIVATE_SUCCESS,
      payload: data,
    };
  }

  static activateFailed(data): Action {
    return {
      type: timeOffActionType.ACTIVATE_FAILED,
      payload: data,
    };
  }
}
